// Styles
@import "./toastify.scss";

$colorMuted: #6c757d;

main {
    background-color: #f8f9fa;
    padding-bottom: 1rem;
}

body {
    background-color: #fff;

    div.root {
        margin: 0;
        padding: 0;
    }
}

footer {
    h5 {
        color: darken($colorMuted, 5%);
    }
}

.logo {
    text-decoration: none !important;
    color: #212529 !important;

    .lead {
        margin: 0;
    }
}

.alert {
    margin-top: 1rem;

    .btn {
        padding: 0;
        margin: 0;
        vertical-align: inherit;
        border: none;
    }
}

.dropzone {
    min-height: 15rem;
}

.card {
    h4 {
        font-size: 1rem;
    }

    .btn {
        padding: 0 0.4rem 0 0;
        font-size: 80%;
    }

    .card-text {
        // taken from text-muted
        color: darken($colorMuted, 5%);
        font-size: 85%;
    }
}

.rating {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: 75%;
    line-height: 1;
    margin-bottom: 8px;
    color: #a0a0a0;

    &::before {
        content: '●●●●●';
        font-size: 15px;
        font-family: Times; // make sure ★ appears correctly
        letter-spacing: 4px;
        margin-right: 5px;
        background: linear-gradient(90deg, #2a76dd var(--percent), #efefef var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

span.tag {
    transform: scale(0);
    animation: grow 0.5s 1 forwards;

    @for $i from 1 through 10 {
        &:nth-of-type(#{$i}) {
            animation-delay: #{$i * .1}s;
        }
    }
}

main>section {
    position: relative;

    &>.container {

        .next,
        .prev {
            transition: all 0.5s ease;
            color: #000;
            width: 25%;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            text-align: center;
            opacity: 0.5;
            transition: all 0.35s ease;

            &:hover {
                opacity: 1;
            }

            svg {
                width: 25px;
            }
        }

        .next {
            right: 10px;

            &:hover {
                right: 0px;
            }
        }

        .prev {
            left: 10px;

            &:hover {
                left: 0px;
            }
        }
    }
}

.large .card-img-top {
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card {
    a {
        width: 100%;
        height: 100%;
        position: relative;

        .btn-rotate {
            position: absolute;
            color: #fff;
            padding: 0;
            border: none;
            border-radius: unset;
            top: 10px;
            right: 15px;
        }

        img.card-img-top {
            transition: filter 0.5s ease-in-out;
            filter: grayscale(0);
        }

        img.card-img-hover {
            position: absolute;
            left: 0;
            vertical-align: top;
            opacity: 0;
            width: 100%;
            transition: opacity 0.5s ease-in-out;
        }

        h4.img-info {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 95%;
            transform: translate(-50%, -50%);
            text-align: center;
            transition: opacity 0.5s ease-in-out;
            color: #fff;
            font-size: 3rem;
            opacity: 0;
        }

        div.img-stats {
            position: absolute;
            right: 5%;
            bottom: 5%;
            text-align: right;
            font-size: 1.5rem;
            color: #fff;
            font-weight: bold;
            transition: opacity 0.5s ease-in-out;
            opacity: 1;
            text-shadow: 0 0 10px #000;
        }

        &:hover {

            .btn-rotate {
                &:hover {
                    color: #007bff;
                }
            }

            img.card-img-top {
                filter: grayscale(90%);
            }

            img.card-img-hover {
                opacity: 0.6;
            }

            h4.img-info {
                opacity: 1;
            }

            div.img-stats {
                opacity: 0;
            }
        }
    }
}

.upload-card {
    &>div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        width: 80%;
    }

    position: relative;
    width: 100%;
    height: 100%;

    cursor: pointer;
    transition: background-color 0.5s ease-in-out;

    // hover and drag active styles are the same :)
    &:hover,
    &.dragActive {
        animation: colorWaveEffect 2s ease-in-out infinite alternate;

        &>div {
            animation: bounceBig 0.5s ease infinite alternate;
        }
    }
}

// deletion styles
.card {
    transition: background-color 250ms, opacity 250ms;

    &.deleting-preparing {
        opacity: 0.8;
        background-color: #ccc;
    }

    // TODO: different styles for deleting and rotating?
    &.deleting,
    &.rotating {
        opacity: 0.5;
    }

    &.deleting-successful {
        animation: shrinkSpin 2s ease 1 forwards;
    }
}

@keyframes shrinkSpin {
    0% {
        transform: scale(1) rotate(0deg);
    }

    100% {
        transform: scale(0.001) rotate(720deg);
    }
}

@keyframes bounceBig {
    0% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-50%, -60%);
    }
}

@keyframes colorWaveEffect {
    from {
        background-color: rgba(74, 190, 210, 0.5);
    }

    to {
        background-color: rgba(47, 150, 240, 0.8);
    }
}

@keyframes grow {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.row:before,
.row:after {
    display: none !important;
}

.mini {
    font-size: 70%;
}

.specialTest {
    transition: all;
}

// very special styles needed for the presentation page
.reveal.center {
    height: 100vh;
}

[hidden] {
    display: inherit !important;
}